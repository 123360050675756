import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Importa Router, Routes y Route
import Chat from './components/Chat';
import TermsAndConditions from './components/TermsAndConditions';
import Sources from './components/Sources';
import Header from './components/Header';

function App() {
  return (
    <Router>
      <Header />
      <main>
        <Routes>
          <Route path="/" element={<Chat />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/sources" element={<Sources />} />
        </Routes>
      </main>
      <footer className="mt-auto text-white-50">
      </footer>
    </Router>
  );
}

export default App;
