import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link } from 'react-router-dom';


const Chat = () => {
    const [showSuggestions, setShowSuggestions] = useState(true);
    const [selectedMessage, setSelectedMessage] = useState('');
    const [sending, setSending] = useState(false);

    const setCookie = (name, value, days) => {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        const expires = "expires=" + date.toUTCString();
        document.cookie = name + "=" + value + ";" + expires + ";path=/";
    };

    const convertToLinks = (text) => {
        return text.replace(/\[([^\]]+)\]/g, function(match, url) {
            return `<a href="${url}" target='_blank'>${url}</a>`;
        });
    }

    const getCookie = (name) => {
        const cname = name + "=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const cookieArray = decodedCookie.split(';');
        for (let i = 0; i < cookieArray.length; i++) {
            let cookie = cookieArray[i];
            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1);
            }
            if (cookie.indexOf(cname) === 0) {
                return cookie.substring(cname.length, cookie.length);
            }
        }
        return "";
    };

    // Maneja el clic en una sugerencia
    const handleSuggestionClick = (message) => {
        setSelectedMessage(message);
        setShowSuggestions(false); // Ocultar todas las sugerencias
    };

    const addMessage = (responseText, isSender) => {
        // Crea el contenedor padre .row
        const rowDiv = document.createElement('div');
        rowDiv.className = 'row mb-3';
    
        // Crea la columna .col-10
        const colDiv = document.createElement('div');
        colDiv.className = isSender ? 'col-10 offset-2' : 'col-10';
    
        // Crea el mensaje
        const responseMsgDiv = document.createElement('div');
        responseMsgDiv.className = isSender ? 'sender-msg' : 'response-msg';
    
        // Crea el span y agrega el texto de la respuesta
        const span = document.createElement('span');
        span.innerHTML = convertToLinks(responseText);
    
        // Agrega el span al div de mensaje
        responseMsgDiv.appendChild(span);
        // Agrega el div de mensaje a la columna
        colDiv.appendChild(responseMsgDiv);
        // Agrega la columna al contenedor padre
        rowDiv.appendChild(colDiv);
        // Agrega la fila al contenedor chat
        var containerChat = document.getElementById('container-chat');
        containerChat.appendChild(rowDiv);

        containerChat.scrollTop = containerChat.scrollHeight;
      };

    const handleSendMessage = async () => {

        const maxMessages = 10;
        let messageCount = parseInt(getCookie('messageCount')) || 0;

        if (messageCount >= maxMessages) {
            alert('Has alcanzado el límite de mensajes.');
            return;
        }

        if (selectedMessage.trim() === '') {
          alert('El mensaje no puede estar vacío');
          return;
        }
    
        setSending(true);
    
        try {
            addMessage(selectedMessage, true);
            setSelectedMessage('')
            if(process.env.REACT_APP_ENVIRONMENT === "prod") {
                messageCount += 1;
                setCookie('messageCount', messageCount, 1);
            }
            
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/send`, {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                },
                body: JSON.stringify({ 
                    content: selectedMessage
                }),
            });

          
            if (!response.ok) {
                throw new Error('Error al enviar el mensaje');
            }
    
            const result = await response.json();
            addMessage(result.content, false);
        } catch (error) {
            console.error('Error:', error);
            alert('Hubo un error al enviar el mensaje');
        } finally {
            setSending(false); 
        }
      };

    return (
    <>
        <div className="row">
            <div className="description-container px-5 pt-2">
                <div className="col-12">
                <div className="text-md-start">
                    <p>El poder ¿acorta la memoria?</p>
                    <p>El Presidente parece no acordarse de lo que opinaba sobre la corrupción, la violencia, la reforma jubilatoria, y muchas cosas más.</p>
                    <p>Por eso, desde la 77, decidimos recordárselo, creando un bot que responde lo que pensaba Lacalle Pou antes de ser presidente.</p>
                </div>
                </div>  
            </div>
            <div className="button-container mt-4">
                <div className="col-12">
                    <a href="whatsapp://send?text=El%20poder%20¿acorta%20la%20memoria?%0A%0AEl%20Presidente%20parece%20no%20acordarse%20de%20lo%20que%20opinaba%20sobre%20la%20corrupci%C3%B3n%2C%20la%20violencia%2C%20la%20reforma%20jubilatoria%2C%20y%20muchas%20cosas%20m%C3%A1s.%0A%0APor%20eso%2C%20desde%20la%2077%2C%20decidimos%20record%C3%A1rselo%2C%20creando%20un%20bot%20que%20responde%20lo%20que%20pensaba%20Lacalle%20Pou%20antes%20de%20ser%20presidente.%0A%0Ahttps%3A%2F%2Fluislacallebot.com%2F" type="button" className="btn btn-success">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-whatsapp mx-1" viewBox="0 0 16 16">
                        <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232"></path>
                        </svg>
                        Compartir
                    </a>
                </div>
            </div>
            <div className="chat mt-4 mb-3 p-0">
                <div className="header-chat">
                    <div className="row pt-3 pb-3">
                    <div className="col-2 offset-1">
                        <img src="assets/img/profile-llb.png" width={70} height={70} className="float-center profile-image" alt="..." />
                    </div>
                    <div className="col-9">
                        <p className="text-start mt-3 mb-0">
                            <b className="title-chat align-middle">Luis Lacalle Bot</b>
                        </p>
                        <p>
                        {sending ? (
                                <span className='text-left writting' style={{float: 'left', fontSize: "12px"}}>Escribiendo...</span>
                                ) : (<span></span>)
                            }    
                        </p>
                        <div className="col-12">
                        
                        </div>
                    </div>
                    </div>
                </div>
                <div className="container-chat px-3 mb-1 pt-3" id="container-chat">
                {showSuggestions && ( 
                    <>
                        <div className="row mb-3 suggest-main">
                            <div className="col-6">
                                <div className="suggest-message-box" onClick={() => handleSuggestionClick('¿Vas a suspender la búsqueda de los desaparecidos?')}>
                                <span>¿Vas a suspender la búsqueda de los desaparecidos?</span>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="suggest-message-box" onClick={() => handleSuggestionClick('¿Va a bajar el precio de los combustibles?')}>
                                <span>¿Va a bajar el precio de los combustibles?</span>
                                </div>
                            </div>
                        </div>
                        <div className="row">  
                            <div className="col-6">
                                <div className="suggest-message-box" onClick={() => handleSuggestionClick('¿Qué vas a hacer con los corruptos en tu gobierno?')}>
                                <span>¿Qué vas a hacer con los corruptos en tu gobierno?</span>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="suggest-message-box" onClick={() => handleSuggestionClick('¿Vas a derogar el aborto?')}>
                                <span>¿Vas a derogar el aborto?</span>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                </div>
                <div className="footer-chat px-3 mb-1">
                    <div className="row">  
                        <div className="col-lg-11 col-10">  
                            <textarea 
                                className="form-control" 
                                value={selectedMessage} 
                                onChange={(e) => setSelectedMessage(e.target.value)} 
                                placeholder="Escribe un mensaje"
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter' && !e.shiftKey) { 
                                        e.preventDefault(); 
                                        handleSendMessage();
                                    }
                                }}
                            />
                        </div>
                        <div className="col-lg-1 col-2 button-content">
                            <button type="button" className="btn btn-secondary" onClick={handleSendMessage}>
                            {sending ? (
                                <div className="spinner-border text-danger" role="status">
                                    <span className="sr-only"></span>
                                </div>
                            ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-send-fill" viewBox="0 0 16 16">
                                <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471z"></path>
                                </svg>
                            )}
                            </button>
                            <ReCAPTCHA
                                sitekey={process.env.REACT_APP_SITE_KEY} // Reemplaza con tu clave del sitio
                                size="invisible"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="row mt-2">
            <div className="col-12 knowledge-container text-start">
                <b>Conocé el origen de las respuestas</b>
            </div>
        </div>

        <div className="row mt-4 sources-button">
            <div className="col-12">
                <Link to={"/sources"} className="btn">
                    <b>Ver fuentes</b>
                </Link>
            </div>
        </div>

        <div className="row mt-5">
            <div className="col-12 d-flex flex-column align-items-center">
                <div className="social-container">
                    <a href="https://www.facebook.com/LaVertienteFA/" target="_blank" rel="noopener noreferrer" className="text-danger mb-3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16">
                        <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951"/>
                        </svg>
                    </a>
                    <a href="https://twitter.com/LaVertienteFA" target="_blank" rel="noopener noreferrer" className="text-danger mb-3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-twitter-x" viewBox="0 0 16 16">
                        <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
                        </svg>
                    </a>
                    <a href="https://www.instagram.com/lavertientefa/" target="_blank" rel="noopener noreferrer" className="text-danger mb-3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-instagram" viewBox="0 0 16 16">
                        <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334"/>
                        </svg>
                    </a>
                    <a href="https://www.youtube.com/channel/UCSk8yOoYB1DEyE3NCmjONSw" target="_blank" rel="noopener noreferrer" className="text-danger mb-3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-youtube" viewBox="0 0 16 16">
                        <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z"/>
                        </svg>
                    </a>
                </div>
            </div>
        </div>

        <div className="row mt-4">
            <div className="col-12">
                <Link to="/terms-and-conditions">
                        Términos y Condiciones | Política de Privacidad
                </Link>
            </div>
        </div>
    </>
    );
}
export default Chat;
