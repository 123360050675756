import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const articles = [
    {
        link: "https://www.sudestada.com.uy/articleId__f934a5c5-9097-44ef-9969-b2e05db4badf/10893/Detalle-de-Noticia",
        title: "Verdades y falsedades en las “40 leyes que Lacalle Pou no votó”"
    },
    {
        link: "https://elpais.com/internacional/2014/10/24/actualidad/1414164629_336419.html",
        title: "“Vamos a derogar muchos artículos de la ley de la marihuana” | Internacional | EL PAÍS"
    },
    {
        link: "https://www.180.com.uy/articulo/75419_si-gana-el-partido-nacional-a-la-delincuencia-se-le-termino-el-recreo",
        title: "180.com.uy :: “Si gana el Partido Nacional a la delincuencia se le terminó el recreo”"
    },
    {
        link: "https://www.elobservador.com.uy/nota/-agenda-provida-que-dijo-lacalle-como-lo-explico-en-campana-y-que-contesto-la-oposicion-20205510220",
        title: "Agenda provida: qué dijo Lacalle, cómo lo explicó en campaña y qué contestó la oposición"
    },
    {
        link: "https://www.carasycaretas.com.uy/casos-de-corrupcion-rodean-a-luis-lacalle-pou",
        title: "Decenas de casos de corrupción rodean a Luis Lacalle Pou"
    },
    {
        link: "https://www.teledoce.com/telemundo/nacionales/lacalle-cuestiono-virulencia-en-criticas-del-fa-y-recordo-que-cuando-renuncio-sendic-la-oposicion-no-hizo-lena-del-arbol-caido/",
        title: "Lacalle cuestionó \"virulencia\" en críticas del FA y recordó que cuando renunció Sendic la oposición no hizo \"leña del árbol caído\" - Teledoce.com"
    },
    {
        link: "https://www.lr21.com.uy/politica/1135376-lacalle-pou-suspendera-busqueda-desaparecidos",
        title: "Lacalle Pou asegura que si es presidente suspende búsqueda de desaparecidos - Noticias Uruguay, LARED21 Diario Digital"
    },
    {
        link: "https://www.elpais.com.uy/informacion/politica/lacalle-pou-hay-que-tener-una-politica-de-desestimulo-de-los-abortos",
        title: "Lacalle Pou: \"Hay que tener una política de desestímulo de los abortos\" - EL PAÍS Uruguay"
    },
    {
        link: "https://www.montevideo.com.uy/Noticias/Lacalle-Pou--Si-Sendic-fuera-companero-de-mi-partido-le-pedia-que-deje-el-cargo--uc301302",
        title: "Lacalle Pou: \"Si Sendic fuera compañero de mi partido, le pedía que deje el cargo\""
    },
    {
        link: "https://www.teledoce.com/telemundo/nacionales/luis-lacalle-pou-el-ministro-del-interior-ha-fracasado-con-total-exito/",
        title: "Luis Lacalle Pou: \"El ministro del Interior ha fracasado con total éxito\" - Teledoce.com"
    },
    {
        link: "https://www.elpais.com.uy/informacion/politica/luis-lacalle-pou-el-poder-no-lo-voy-a-compartir-con-los-sindicatos",
        title: "Luis Lacalle Pou: “El poder no lo voy a compartir con los sindicatos” - EL PAÍS Uruguay"
    },
    {
        link: "https://www.montevideo.com.uy/Noticias/-La-positiva-de-Lacalle-Pou-uc253145",
        title: "“La positiva” de Lacalle Pou"
    },
    {
        link: "https://www.elpais.com.uy/informacion/muchos-uruguayos-no-disfrutan-derechos-basicos",
        title: "\"Muchos uruguayos no disfrutan derechos básicos\" - EL PAÍS Uruguay"
    },
    {
        link: "http://www.180.com.uy/articulo/59479_me-hubiera-gustado-que-vazquez-de-la-cara-en-las-inundaciones",
        title: "180.com.uy :: “Me hubiera gustado que Vázquez dé la cara” en las inundaciones"
    },
    {
        link: "https://www.180.com.uy/articulo/78988_programa-de-lacalle-pou-cita-erroneamente-informe-para-hablar-de-aumento-de-asentamientos",
        title: "180.com.uy :: Programa de Lacalle Pou cita erróneamente informe para hablar de aumento de asentamientos"
    },
    {
        link: "https://www.elobservador.com.uy/nota/mira-online-en-vivo-el-debate-entre-martinez-y-lacalle-pou-desde-las-20-horas-2019101195117",
        title: "Así fue el debate entre Martínez y Lacalle Pou; repasá los mejores cruces"
    },
    {
        link: "https://ladiaria.com.uy/politica/articulo/2019/4/lacalle-pou-aposto-a-un-discurso-emotivo-en-el-lanzamiento-de-su-campana/",
        title: "Lacalle Pou apostó a un discurso emotivo en el lanzamiento de su campaña | la diaria"
    },
    {
        link: "https://ladiaria.com.uy/articulo/2017/9/lacalle-pou-aseguro-que-hay-una-crisis-institucional-y-el-partido-colorado-lo-nego/",
        title: "Lacalle Pou aseguró que hay una “crisis institucional” y el Partido Colorado lo negó | la diaria"
    },
    {
        link: "https://www.montevideo.com.uy/Noticias/Lacalle-Pou-salio-al-cruce-de-Mujica--No-hay-que-tocar-la-edad-jubilatoria--uc713845",
        title: "Lacalle Pou salió al cruce de Mujica: “No hay que tocar la edad jubilatoria”"
    },
    {
        link: "https://ladiaria.com.uy/politica/articulo/2017/7/lacalle-pou-se-suma-a-empresarios-por-plebiscito-contra-la-inclusion-financiera/",
        title: "Lacalle Pou se suma a empresarios por plebiscito contra la inclusión financiera | la diaria | Uruguay"
    },
    {
        link: "https://www.montevideo.com.uy/Noticias/Lacalle-Pou-sobre-matrimonio-igualitario--Si-volviera-atras-lo-votaria--uc732877",
        title: "Lacalle Pou sobre matrimonio igualitario: \"Si volviera atrás lo votaría\""
    },
    {
        link: "https://www.montevideo.com.uy/Noticias/Lacalle-Pou-volvio-a-referirse-a-los-videos-uc253971",
        title: "Lacalle Pou volvió a referirse a los videos"
    },
    {
        link: "https://www.elobservador.com.uy/nota/lacalle-pou-y-el-paralelismo-de-las-gabardinas-de-ancap-con-un-gobierno-de-coalicion-20195520206",
        title: "Lacalle Pou y el paralelismo de las gabardinas de Ancap con un gobierno de coalición"
    },
    {
        link: "https://www.montevideo.com.uy/Noticias/Lacalle-Pou--Beatriz-no-esta-en-la-formula-por-pertenecer-a-un-genero--uc723049",
        title: "Lacalle Pou: \"Beatriz no está en la fórmula por pertenecer a un género\""
    },
    {
        link: "https://www.montevideo.com.uy/Noticias/Lacalle-Pou--Hay-que-aumentar-la-edad-de-retiro-de-militares-y-los-militares-lo-saben--uc716431",
        title: "Lacalle Pou: “Hay que aumentar la edad de retiro de militares, y los militares lo saben\""
    },
    {
        link: "https://www.elpais.com.uy/informacion/politica/lacalle-pou-hay-que-hacerse-responsable-se-terminaron-las-excusas",
        title: "Lacalle Pou: \"Hay que hacerse responsable, se terminaron las excusas\" - EL PAÍS Uruguay"
    },
    {
        link: "https://www.teledoce.com/telemundo/nacionales/luis-lacalle-pou-dijo-que-las-declaraciones-de-danilo-astori-sobre-su-programa-de-gobierno-en-la-web-de-presidencia-muestran-nerviosismo-y-desesperacion/",
        title: "Luis Lacalle Pou dijo que las declaraciones de Danilo Astori sobre su programa de gobierno en la web de Presidencia muestran “nerviosismo y desesperación” - Teledoce.com"
    },
    {
        link: "https://www.poderjudicial.gub.uy/institucional/item/658-luis-lacalle-pou-senalo-que-el-intercambio-tambien-es-una-forma-de-respetar-la-separacion-de-poderes.html",
        title: "Luis Lacalle Pou señaló que el intercambio también es una forma de respetar la separación de poderes"
    },
    {
        link: "https://www.abc.com.py/edicion-impresa/politica/paraguay-y-uruguay-no-pueden-seguir-atados-a-brasil-y-argentina-1577605.html",
        title: "Paraguay y Uruguay no pueden seguir atados a Brasil y Argentina - Política - ABC Color"
    },
    {
        link: "https://www.montevideo.com.uy/Noticias/Sere-Curioso-con-Luis-Lacalle-Pou--Cosas-positivas-de-este-gobierno-Me-cuesta-me-cuesta---uc670064",
        title: "Seré Curioso con Luis Lacalle Pou: \"¿Cosas positivas de este gobierno? Me cuesta, me cuesta...\""
    },
    {
        link: "https://ladiaria.com.uy/articulo/2014/10/siga-el-baile/",
        title: "Siga el baile | la diaria | Uruguay"
    }
];


const ITEMS_PER_PAGE = 11;

const Sources = () => {
    const [currentPage, setCurrentPage] = useState(0);

    useEffect(() => {
        window.scrollTo(0, 0); 
    }, []);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const startIndex = currentPage * ITEMS_PER_PAGE;
    const selectedArticles = articles.slice(startIndex, startIndex + ITEMS_PER_PAGE);
    const totalPages = Math.ceil(articles.length / ITEMS_PER_PAGE);

    return (
        <div className="row">
            <div className="row mt-4 mb-3 sources-button">
                <div className="col-12">
                    <Link to={"/"} className='btn'>
                        <b>Volver al chat</b>
                    </Link>
                </div>
            </div>
            {selectedArticles.map((article) => (
                <div className="description-container mb-3 px-5 py-2" key={article.id}>
                    <div className="col-12 source-container">
                        <div className="text-md-start">
                            <b>
                                <a href={article.link} target="_blank" rel="noreferrer">{article.title}</a>
                            </b>
                        </div>
                    </div>
                </div>
            ))}
            <div className="d-flex justify-content-center mt-3">
            {Array.from({ length: totalPages }, (_, index) => (
                <button
                    key={index}
                    onClick={() => handlePageChange(index)}
                    className={`mx-1 rounded-circle ${currentPage === index ? 'bg-primary' : ''}`}
                    style={{
                        width: '15px',
                        height: '15px',
                        backgroundColor: currentPage === index ? '#007bff' : '#FFE782',
                        border: 'none',
                        cursor: 'pointer',
                        outline: 'none'
                    }}
                />
            ))}
        </div>
        <div className="row mt-5">
            <div className="col-12 d-flex flex-column align-items-center">
                <div className="social-container">
                    <a href="https://www.facebook.com/LaVertienteFA/" target="_blank" rel="noopener noreferrer" className="text-danger mb-3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16">
                        <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951"/>
                        </svg>
                    </a>
                    <a href="https://twitter.com/LaVertienteFA" target="_blank" rel="noopener noreferrer" className="text-danger mb-3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-twitter-x" viewBox="0 0 16 16">
                        <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
                        </svg>
                    </a>
                    <a href="https://www.instagram.com/lavertientefa/" target="_blank" rel="noopener noreferrer" className="text-danger mb-3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-instagram" viewBox="0 0 16 16">
                        <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334"/>
                        </svg>
                    </a>
                    <a href="https://www.youtube.com/channel/UCSk8yOoYB1DEyE3NCmjONSw" target="_blank" rel="noopener noreferrer" className="text-danger mb-3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-youtube" viewBox="0 0 16 16">
                        <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z"/>
                        </svg>
                    </a>
                </div>
            </div>
        </div>

        <div className="row mt-4">
            <div className="col-12">
                <Link to="/terms-and-conditions">
                        Términos y Condiciones | Política de Privacidad
                </Link>
            </div>
        </div>
    </div>
    );
};

export default Sources;
